@font-face {
  font-family: 'MaerskText';
  src: url('./assets/fonts/MaerskText-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'MaerskText';
  src: url('./assets/fonts/MaerskText-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'MaerskText';
  src: url('./assets/fonts/MaerskText-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: 'MaerskTextOffice';
  src: url('./assets/fonts/MaerskTextOffice-Regular.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'MaerskTextOffice';
  src: url('./assets/fonts/MaerskTextOffice-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'MaerskTextOffice';
  src: url('./assets/fonts/MaerskTextOffice-Light.ttf');
  font-weight: lighter;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'MaerskTextOffice';
}

.compressed, label {
  font-family: 'MaerskText'
}

.footer-box {
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(1, 66, 101, 0.6);
  text-align: center;
  padding: 20px;
}

.header {
  color: white;
}

.loader-box {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}

.main-jumbo {
  background: transparent;
  color: white;
  padding-top: 1rem !important;
}

label {
  font-size: 1.2rem;
  margin-left: 8px;
}

body {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  margin: 0;
  font-family: 'MaerskText', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(180deg,#004165 0,rgba(20,139,174,.6)), url('./assets/img/background.jpg'); */
  overflow: hidden;
}

input {
  border: 2px solid #cae2e9 !important;
  text-align: center !important;
  border: 2px solid white;
  font-size: 28px !important;
  font-family: 'MaerskTextOffice' !important;
  color: white !important;
  background-color: rgba(255,255,255,.3) !important;
}

.sweet-alert {
  color: white !important;
  background-color: #1b4560 !important;
  border: 2px solid #ffffff26;
  box-shadow: 0px 0px 20px 4px #ffffff5e;
}

.sweet-alert  p{
  color: white !important;
}

.sweet-alert h2 {
  font-weight: 600;
}

.modalButton {
  font-weight: bold;
  background: linear-gradient(45deg, #EEAF30,#FF6319);
  box-shadow: 0 3px 5px 2px rgba(245, 143, 39, 0.3);
  border-radius: 12px;
  color: white;
  border-color: transparent !important;
}

.modalButtonCancel {
  color: white !important;
}

::placeholder {
  font-family: 'MaerskTextOffice' !important;
  color: rgba(255,255,255,.6) !important;
  font-size: 28px !important;
  opacity: 1;
}

.form-check {
  padding: 4px;
}

input[type='radio']:after {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  top: -14px;
  left: -4px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  top: -14px;
  left: -4px;
  position: relative;
  background-color: #ffa500;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.image-profile {
  max-height: 200px;
  max-width: 200px;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.5s ease;
}

.footer-agree-conditions {
  display: flex;
  padding: 4px;
  justify-content: left;
  height: 100%;
  align-items: center;
}

.footer-checkbox {
  display: flex;
  padding: 4px;
  justify-content: left;
}